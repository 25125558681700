<template>
  <div class="overflow-hidden">
    <div class="container">
      <BaseChipTitle
        :title="`In ${steps?.length} stappen`"
        chip="solliciteren"
        single-line
        center
      />
      <BaseCarousel
        :config="SWIPER_CONFIG"
        :items="steps"
        :bar="true"
        class="container-extend-end"
      >
        <template #item="{title, description, image, index}">
          <div class="h-full overflow-hidden rounded-md bg-white p-4 md:rounded-2xl md:p-10">
            <figure
              v-if="image"
              class="relative"
            >
              <NuxtPicture
                v-if="image?.url"
                :src="image.url"
                :img-attrs="{class: 'h-28 w-full rounded-md object-cover md:aspect-1 md:h-[16rem]'}"
                sizes="600px md:1200px"
              />
              <figcaption
                v-if="formatIndex(index)"
                class="absolute left-3 top-3 flex size-7 items-center justify-center rounded-full bg-white text-[8px] md:size-20 md:text-xl md:leading-9"
              >
                {{ formatIndex(index) }}
              </figcaption>
              <img
                src="~/assets/img/lines.png"
                class="absolute inset-x-0 bottom-0 object-cover"
              >
            </figure>
            <div class="my-4 space-y-1.5 md:mt-10 md:space-y-4">
              <h4 class="font-semibold md:text-3xl lg:max-w-md">
                {{ title }}
              </h4>
              <p class="leading-loose md:text-xl md:leading-loose">
                {{ description }}
              </p>
            </div>
          </div>
        </template>
      </BaseCarousel>
    </div>
  </div>
</template>

<script setup lang="ts">
import type {SwiperOptions} from 'swiper/types/swiper-options';
import {useSollicitatieprocessenQuery} from '~/graphql/graphql';

const SWIPER_BREAK_POINT = 768;

const SWIPER_CONFIG: SwiperOptions = {
  slidesPerView: 1.1,
  spaceBetween: 10,
  centeredSlides: false,
  breakpoints: {
    [SWIPER_BREAK_POINT]: {
      slidesPerView: 1.4,
      spaceBetween: 24,
    },
  },
};

const route = useRoute();
const variables = ref({
  type: route.name === 'bedankt' ? 'Bedankt' : 'Algemeen',
});

const {result: sollicitatieprocessenResult} = useSollicitatieprocessenQuery(variables);
const steps = computed(() => {
  return sollicitatieprocessenResult.value?.sollicitatieprocessen?.map((item) => {
    return {
      image: item.active_image,
      title: item.title,
      description: item.omschrijving,
    };
  }) ?? [];
});

const formatIndex = (index: number) => (index + 1).toLocaleString('en-US', {
  minimumIntegerDigits: 2,
  useGrouping: false,
});
</script>
